import { StandaloneMarkup } from 'base/components/Markup';
import useUserAuth from 'lib/hooks/useUserAuth';
import { useUserName } from 'lib/hooks/useUserName';

export const AllasStandaloneMarkup: typeof StandaloneMarkup = ({ markup, ...props }) => {
  const { isUserLoggedIn } = useUserAuth();
  const { firstName } = useUserName();

  const showMemberMarkup = isUserLoggedIn && markup?.includes('id="allas-member-welcome"');

  const memberMarkup = (
    <div className="bg-logga-300 mt-3 p-6 text-center">
      <h2 className="mb-3 text-headline-sm">Välkommen {firstName}</h2>
      <div className="text-body-md">
        Vill du hantera dina print-prenumerationer?{' '}
        <a href="https://prenumerera.se/kundservice/" className="font-normal hover:underline">
          Det kan du göra här
        </a>
      </div>
    </div>
  );

  return showMemberMarkup ? memberMarkup : <StandaloneMarkup {...{ markup }} {...props} />;
};
