import { tw } from '@/utils/tw';
import { default as BaseCoverBox } from 'base/components/CoverBox/theme';
import { merge } from '@/utils/merge';

const Prose = BaseCoverBox.Prose;

const CoverBox = tw.theme({
  extend: BaseCoverBox,
  slots: {
    base: 'bg-white wings-white',
    headline: 'text-petroleum-600 text-headline-xs sm:text-headline-sm',
    toggle: 'text-body-sm sm:text-body-md bg-white',
  },
  variants: merge(BaseCoverBox.variants, {
    center: {
      true: {
        headline: `sm:text-headline-md`,
      },
    },
  }),
});

export default Object.assign(CoverBox, { Prose });
